@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Sora', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size:3em;
  font-weight: 700;
}
